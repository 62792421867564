/* unplugin-vue-components disabled */import { NavBar as __unplugin_components_0 } from 'vant/es';import 'vant/es/nav-bar/style/index';
import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, KeepAlive as _KeepAlive, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  style: {
    "padding-bottom": "100px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_nav_bar = __unplugin_components_0;

  var _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createCommentVNode("    <van-pull-refresh v-model=\"isLoading\" :head-height=\"80\" @refresh=\"refresh\">"), _createVNode(_component_van_nav_bar, {
    "z-index": "2",
    title: $data.title,
    "left-text": "返回",
    "left-arrow": "",
    onClickLeft: $options.back
  }, null, 8
  /* PROPS */
  , ["title", "onClickLeft"]), _createVNode(_component_router_view, null, {
    default: _withCtx(function (_ref) {
      var Component = _ref.Component;
      return [(_openBlock(), _createBlock(_KeepAlive, {
        include: _ctx.keepAlive
      }, [_createElementVNode("div", _hoisted_1, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))])], 1032
      /* PROPS, DYNAMIC_SLOTS */
      , ["include"]))];
    }),
    _: 1
    /* STABLE */

  }), _createCommentVNode("    </van-pull-refresh>")], 64
  /* STABLE_FRAGMENT */
  );
}