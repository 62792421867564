import "core-js/modules/es.array.concat.js";
import request from '@/plugins/request';
import Setting from '@/setting';
import router from "@/router";
import Utils from '@/utils';
import { showConfirmDialog } from 'vant';
var WxApi = {};

WxApi.getJsapiTicket = function (url) {
  return request({
    url: "/wx/jsapi/".concat(Setting.wx.appid, "/getJsapiTicket?url=").concat(url),
    method: 'get'
  });
};

WxApi.authorize = function (_ref) {
  var _ref$scope = _ref.scope,
      scope = _ref$scope === void 0 ? 'snsapi_userinfo' : _ref$scope,
      _ref$route = _ref.route,
      route = _ref$route === void 0 ? router.currentRoute.value : _ref$route,
      redirectUrl = _ref.redirectUrl;
  var fullPath = route.fullPath,
      query = route.query;

  if (!redirectUrl) {
    redirectUrl = process.env.VUE_APP_URL + fullPath;

    if (Utils.Json.isNotNull(query)) {
      redirectUrl += "?" + Utils.Json.buildParams(query);
    }
  }

  var url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=".concat(Setting.wx.appid, "&redirect_uri=").concat(redirectUrl, "&response_type=code&scope=").concat(scope, "&#wechat_redirect");
  location.href = url; // showConfirmDialog({
  //     title: '是否授权',
  //     message: `请问是否授权${url}`,
  // }).then(() => {
  //     location.href = url
  // }).catch(() => {
  //
  // });
};

export default WxApi;