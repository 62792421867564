import _toConsumableArray from "/home/jenkins/agent/workspace/psychologicalhwvvw/psychological-we-web/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.string.split.js";
import Utils from './tool';
var ArrayUtils = {
  search: function search(treeList, callback) {
    return new Promise(function (resolve) {
      treeList.forEach(function (node, index) {
        if (callback && callback(node)) {
          resolve({
            node: node,
            tIndex: index,
            treeList: treeList
          });
        } else if (node.children && node.children.length > 0) {
          ArrayUtils.search(node.children, callback).then(function (item) {
            resolve(item);
          });
        }
      });
    });
  },
  findTreeList: function findTreeList(treeList, callback) {
    if (Utils.isNull(treeList)) {
      return null;
    }

    return new Promise(function (resolve) {
      treeList.forEach(function (item, index) {
        if (callback && callback(item)) {
          resolve({
            item: item,
            tIndex: index,
            treeList: treeList
          });
        } else if (item.children && item.children.length > 0) {
          ArrayUtils.findTreeList(item.children, callback).then(function (param) {
            resolve(param);
          });
        }
      });
    });
  },
  convertTreeList2NormalList: function convertTreeList2NormalList(treeList) {
    if (Utils.isNull(treeList)) {
      return null;
    }

    var list = [];
    treeList.forEach(function (item) {
      list.push(item);

      if (item.children && item.children.length > 0) {
        var childrenList = ArrayUtils.convertTreeList2NormalList(item.children);
        list.push.apply(list, _toConsumableArray(childrenList));
        item.childrenCount = childrenList.length;
      } else {
        item.childrenCount = 0;
      }

      delete item.children;
    });
    return list;
  },

  /**
   * 通过num生成指定数组，比如num=5返回[0,1,2,3,4,5]
   * @param num 数字
   * @returns {null|[]}
   */
  buildArray: function buildArray(num) {
    if (num) {
      var array = [];

      for (var i = 0; i <= num; i++) {
        array.push(i);
      }

      return array;
    }

    return [];
  },

  /**
   * 格式化数组，如果数组中的对象均为null，则返回一个空数组
   * @param num
   * @returns {[]|*[]}
   */
  formatArray: function formatArray(array) {
    if (array.filter(function (item) {
      return Utils.isNotNull(item);
    }).length > 0) {
      return array;
    }

    return [];
  },
  getTreeListSize: function getTreeListSize(treeList, count) {
    count = count || 0;
    treeList.forEach(function (tree) {
      count++;

      if (Utils.isNotNull(tree.children)) {
        count = ArrayUtils.getTreeListSize(tree.children, count);
      }
    });
    return count;
  },
  split: function split(array) {
    var splitCount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;

    if (Utils.isNull(array)) {
      return [];
    }

    return [array.splice(0, splitCount)].concat(_toConsumableArray(ArrayUtils.split(array, splitCount)));
  },
  buildViewTreeData: function buildViewTreeData(list, s, parent) {
    var a = {};
    list.forEach(function (item) {
      a[item.value] = item.label;

      if (Utils.isNotNull(item.children)) {
        s[item.value] = ArrayUtils.buildViewTreeData(item.children, s, item.value);
      }
    });
    s[parent] = a;
    return a;
  }
};
export default ArrayUtils;