import { showSuccessToast, showFailToast, closeToast, showLoadingToast, showNotify, closeNotify } from 'vant';
export default {
  Notify: {
    info: function info(content) {
      showNotify({
        type: 'primary',
        message: content
      });
    },
    success: function success(content) {
      showNotify({
        type: 'success',
        message: content
      });
    },
    error: function error(content) {
      showNotify({
        type: 'danger',
        message: content
      });
    },
    warning: function warning(content) {
      showNotify({
        type: 'warning',
        message: content
      });
    },
    destroy: function destroy() {
      closeNotify();
    }
  },
  Message: {
    success: function success(content) {
      showSuccessToast(content);
    },
    error: function error(content) {
      showFailToast(content);
    },
    loading: function loading() {
      var content = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '加载中...';
      var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      return showLoadingToast({
        duration: duration,
        message: content,
        forbidClick: true
      });
    },
    clear: function clear() {
      closeToast();
    }
  }
};