/* unplugin-vue-components disabled */import _objectSpread from "/home/jenkins/agent/workspace/psychologicalhwvvw/psychological-we-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/home/jenkins/agent/workspace/psychologicalhwvvw/psychological-we-web/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _components;

import "core-js/modules/es.function.name.js";
import { Tabbar, TabbarItem } from 'vant';
import { mapState } from 'vuex';
export default {
  name: 'BasicLayout',
  components: (_components = {}, _defineProperty(_components, Tabbar.name, Tabbar), _defineProperty(_components, TabbarItem.name, TabbarItem), _components),
  data: function data() {
    return {
      active: 'index'
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState('admin/page', ['keepAlive'])), mapState('admin/user', ['user', 'hasUnion'])),
  watch: {
    $route: {
      handler: function handler(currentRoute) {
        this.active = currentRoute.name;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleChangeTabbar: function handleChangeTabbar(name) {
      this.$router.push({
        name: name
      });
    }
  }
};