import _objectSpread from "/home/jenkins/agent/workspace/psychologicalhwvvw/psychological-we-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.concat.js";
import { h } from 'vue';
import BasicLayout from '@/layouts/basic-layout';
import InfoLayout from '@/layouts/basic-layout/info';
import NormalLayout from '@/layouts/basic-layout/normal';
import Constant from '@/utils/constant';
/**
 * 在主框架内显示
 */

var needLoginMeta = {
  needLogin: true
};
var frameIn = [{
  path: '/',
  redirect: {
    name: 'index'
  },
  component: BasicLayout,
  children: [{
    path: '/index',
    name: 'index',
    component: function component() {
      return import('@/pages');
    }
  }, {
    path: '/appointment',
    name: 'appointment',
    meta: _objectSpread({}, needLoginMeta),
    component: function component() {
      return import('@/pages/appointment');
    }
  }, {
    path: '/home',
    name: 'home',
    meta: _objectSpread({}, needLoginMeta),
    component: function component() {
      return import('@/pages/home');
    }
  }, {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: {
      beforeRouteEnter: function beforeRouteEnter(to, from, next) {
        next(function (instance) {
          return instance.$router.replace(from.fullPath);
        });
      },
      render: function render() {
        return h();
      }
    }
  }, // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: {
      beforeRouteEnter: function beforeRouteEnter(to, from, next) {
        next(function (instance) {
          return instance.$router.replace(JSON.parse(from.params.route));
        });
      },
      render: function render() {
        return h();
      }
    }
  }]
}];
/**
 * 在主框架之外显示
 */

var frameOut = [{
  path: '/login',
  name: 'login',
  meta: {
    title: '理心 - 登录'
  },
  component: function component() {
    return import('@/pages/login');
  }
}];
var frameInfo = [{
  path: '/',
  component: InfoLayout,
  children: [{
    path: '/userInfo',
    name: 'userInfo',
    meta: _objectSpread(_objectSpread({}, needLoginMeta), {}, {
      back: 'home',
      title: '个人中心 - 基本信息',
      navTitle: '基本信息',
      jsApiList: Constant.JsApi.userBaseInfo
    }),
    component: function component() {
      return import('@/pages/userInfo');
    }
  }, {
    path: '/bindStudentInfo',
    name: 'bindStudentInfo',
    meta: _objectSpread(_objectSpread({}, needLoginMeta), {}, {
      back: 'home',
      title: '绑定学籍信息',
      navTitle: '绑定学籍信息'
    }),
    component: function component() {
      return import('@/pages/studentInfo');
    }
  }, {
    path: '/studentInfo',
    name: 'studentInfo',
    meta: _objectSpread(_objectSpread({}, needLoginMeta), {}, {
      back: 'home',
      title: '学籍信息',
      navTitle: '查看学籍信息'
    }),
    component: function component() {
      return import('@/pages/studentInfo/info');
    }
  }, {
    path: '/consult',
    name: 'consult',
    meta: _objectSpread(_objectSpread({}, needLoginMeta), {}, {
      title: '咨询记录',
      navTitle: '咨询记录'
    }),
    component: function component() {
      return import('@/pages/consult');
    }
  }, {
    path: '/appointmentRecord',
    name: 'appointmentRecord',
    meta: _objectSpread(_objectSpread({}, needLoginMeta), {}, {
      title: '预约记录',
      navTitle: '预约记录',
      back: 'home'
    }),
    component: function component() {
      return import('@/pages/appointment/record');
    }
  }, {
    path: '/userAnswerList',
    name: 'userAnswerList',
    meta: _objectSpread(_objectSpread({}, needLoginMeta), {}, {
      title: '问卷记录',
      navTitle: '问卷记录',
      back: 'home'
    }),
    component: function component() {
      return import('@/pages/appointmentAnswer/userAnswerList');
    }
  }, {
    path: '/userAnswerInfo/:answerUserId',
    name: 'userAnswerInfo',
    meta: _objectSpread(_objectSpread({}, needLoginMeta), {}, {
      title: '问卷详情',
      navTitle: '问卷详情',
      back: 'userAnswerList'
    }),
    component: function component() {
      return import('@/pages/appointmentAnswer/userAnswerInfo');
    }
  }]
}];
var frameModify = [{
  path: '/',
  component: NormalLayout,
  children: [{
    path: '/modifyNickName',
    name: 'modifyNickName',
    meta: _objectSpread(_objectSpread({}, needLoginMeta), {}, {
      title: '个人中心 - 基本信息 - 修改昵称'
    }),
    component: function component() {
      return import('@/pages/userInfo/modifyUserBaseInfo/nickName');
    }
  }, {
    path: '/modifySex',
    name: 'modifySex',
    meta: _objectSpread(_objectSpread({}, needLoginMeta), {}, {
      title: '个人中心 - 基本信息 - 修改性别'
    }),
    component: function component() {
      return import('@/pages/userInfo/modifyUserBaseInfo/sex');
    }
  }, {
    path: '/modifyStudentInfo',
    name: 'modifyStudentInfo',
    meta: _objectSpread(_objectSpread({}, needLoginMeta), {}, {
      title: '个人中心 - 修改学籍信息'
    }),
    component: function component() {
      return import('@/pages/studentInfo/modify');
    }
  }, {
    path: '/modifyPhone',
    name: 'modifyPhone',
    meta: _objectSpread(_objectSpread({}, needLoginMeta), {}, {
      title: '个人中心 - 修改手机号'
    }),
    component: function component() {
      return import('@/pages/studentInfo/modifyPhone/index');
    }
  }, {
    path: '/changePhone',
    name: 'changePhone',
    meta: _objectSpread(_objectSpread({}, needLoginMeta), {}, {
      cache: true,
      title: '个人中心 - 修改手机号'
    }),
    component: function component() {
      return import('@/pages/studentInfo/modifyPhone/changePhone');
    }
  }, {
    path: '/checkValidCode',
    name: 'checkValidCode',
    meta: _objectSpread(_objectSpread({}, needLoginMeta), {}, {
      title: '个人中心 - 修改手机号'
    }),
    component: function component() {
      return import('@/pages/studentInfo/modifyPhone/checkValidCode');
    }
  }, {
    path: '/appointmentAnswer',
    name: 'appointmentAnswer',
    meta: _objectSpread({
      title: '预约问卷'
    }, needLoginMeta),
    component: function component() {
      return import('@/pages/appointmentAnswer/index');
    }
  }, {
    path: '/answer/:code',
    name: 'redirectAnswer',
    meta: _objectSpread({}, needLoginMeta),
    redirect: function redirect(to) {
      return {
        name: 'answer',
        params: _objectSpread(_objectSpread({}, to.params), {}, {
          docNumber: 0
        }),
        query: _objectSpread({}, to.query)
      };
    }
  }, {
    path: '/answer/:code/:docNumber',
    name: 'answer',
    meta: _objectSpread({}, needLoginMeta),
    component: function component() {
      return import('@/pages/answer');
    }
  }]
}];
/**
 * 错误页面
 */

var errorPage = [{
  path: '/403',
  name: '403',
  meta: {
    title: '403'
  },
  component: function component() {
    return import('@/pages/system/error/403');
  }
}, {
  path: '/500',
  name: '500',
  meta: {
    title: '500'
  },
  component: function component() {
    return import('@/pages/system/error/500');
  }
}, {
  path: '/:pathMatch(.*)',
  name: '404',
  meta: {
    title: '404'
  },
  component: function component() {
    return import('@/pages/system/error/404');
  }
}]; // 重新组织后导出

export default [].concat(frameIn, frameOut, frameInfo, frameModify, errorPage);