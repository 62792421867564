import "core-js/modules/es.array.concat.js";
import request from '@/plugins/request';
import Setting from '@/setting';
export function AccountLogin(_ref) {
  var _ref$appid = _ref.appid,
      appid = _ref$appid === void 0 ? Setting.wx.appid : _ref$appid,
      code = _ref.code;
  return request({
    url: "/login/".concat(appid, "/").concat(code),
    method: 'get'
  });
}
export function AccountRegister(data) {
  return request({
    url: '/api/register',
    method: 'post',
    data: data
  });
}