/* unplugin-vue-components disabled */import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  style: {
    "padding-bottom": "100px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(function (_ref) {
      var Component = _ref.Component;
      return [(_openBlock(), _createBlock(_KeepAlive, {
        include: _ctx.keepAlive
      }, [_createElementVNode("div", _hoisted_1, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))])], 1032
      /* PROPS, DYNAMIC_SLOTS */
      , ["include"]))];
    }),
    _: 1
    /* STABLE */

  });
}