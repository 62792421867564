import _toConsumableArray from "/home/jenkins/agent/workspace/psychologicalhwvvw/psychological-we-web/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import { get } from 'lodash';
import routes from '@/router/routes'; // 判定是否需要缓存

var isKeepAlive = function isKeepAlive(data) {
  return get(data, 'meta.cache', false);
};

var getKeepAliveList = function getKeepAliveList(routers) {
  var list = routers.filter(function (item) {
    return isKeepAlive(item);
  }).map(function (e) {
    return e.name;
  });
  list.push.apply(list, _toConsumableArray(routers.map(function (item) {
    if (item.children && item.children.length > 0) {
      return getKeepAliveList(item.children);
    } else {
      return [];
    }
  }).reduce(function (a, b) {
    a.push.apply(a, _toConsumableArray(b));
    return a;
  })));
  return list;
};

export default {
  namespaced: true,
  state: {
    // 当前页面
    current: '',
    // 需要缓存的页面 name
    keepAlive: []
  },
  mutations: {
    /**
     * @class keepAlive
     * @description 从已经打开的页面记录中更新需要缓存的页面记录
     * @param {Object} state vuex state
     */
    keepAliveRefresh: function keepAliveRefresh(state) {
      state.keepAlive = getKeepAliveList(routes);
    },

    /**
     * @description 删除一个页面的缓存设置
     * @param {Object} state vuex state
     * @param {String} name name
     */
    keepAliveRemove: function keepAliveRemove(state, name) {
      var list = _toConsumableArray(state.keepAlive);

      var index = list.findIndex(function (item) {
        return item === name;
      });

      if (index !== -1) {
        list.splice(index, 1);
        state.keepAlive = list;
      }
    },

    /**
     * @description 增加一个页面的缓存设置
     * @param {Object} state vuex state
     * @param {String} name name
     */
    keepAlivePush: function keepAlivePush(state, name) {
      var keep = _toConsumableArray(state.keepAlive);

      keep.push(name);
      state.keepAlive = keep;
    },

    /**
     * @description 清空页面缓存设置
     * @param {Object} state vuex state
     */
    keepAliveClean: function keepAliveClean(state) {
      state.keepAlive = [];
    },

    /**
     * @class current
     * @description 设置当前激活的页面 fullPath
     * @param {Object} state vuex state
     * @param {String} fullPath new fullPath
     */
    currentSet: function currentSet(state, fullPath) {
      state.current = fullPath;
    }
  }
};