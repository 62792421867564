import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
import Tool from './tool';
import Pattern from './pattern';
var Form = {
  Rule: {
    required: function required(_ref) {
      var _ref$type = _ref.type,
          type = _ref$type === void 0 ? 'string' : _ref$type,
          _ref$label = _ref.label,
          label = _ref$label === void 0 ? '' : _ref$label,
          _ref$trigger = _ref.trigger,
          trigger = _ref$trigger === void 0 ? 'onBlur' : _ref$trigger,
          _ref$message = _ref.message,
          message = _ref$message === void 0 ? "\u8BF7\u8F93\u5165".concat(label) : _ref$message;
      return {
        type: type,
        required: true,
        message: message,
        trigger: trigger
      };
    },
    max: function max(_ref2) {
      var _ref2$label = _ref2.label,
          label = _ref2$label === void 0 ? '' : _ref2$label,
          _ref2$max = _ref2.max,
          _max = _ref2$max === void 0 ? Number : _ref2$max,
          _ref2$trigger = _ref2.trigger,
          trigger = _ref2$trigger === void 0 ? 'onBlur' : _ref2$trigger,
          _ref2$message = _ref2.message,
          message = _ref2$message === void 0 ? "".concat(label, "\u6700\u591A").concat(_max, "\u4E2A\u5B57\u7B26") : _ref2$message;

      return {
        min: 1,
        max: _max,
        message: message,
        trigger: trigger
      };
    },
    min: function min(_ref3) {
      var _ref3$label = _ref3.label,
          label = _ref3$label === void 0 ? '' : _ref3$label,
          _ref3$min = _ref3.min,
          _min = _ref3$min === void 0 ? Number : _ref3$min,
          _ref3$trigger = _ref3.trigger,
          trigger = _ref3$trigger === void 0 ? 'onBlur' : _ref3$trigger,
          _ref3$message = _ref3.message,
          message = _ref3$message === void 0 ? "".concat(label, "\u6700\u5C11").concat(_min, "\u4E2A\u5B57\u7B26") : _ref3$message;

      return {
        min: _min,
        message: message,
        trigger: trigger
      };
    },
    email: function email(_ref4) {
      var _ref4$trigger = _ref4.trigger,
          trigger = _ref4$trigger === void 0 ? 'onBlur' : _ref4$trigger,
          _ref4$message = _ref4.message,
          message = _ref4$message === void 0 ? '邮箱格式不正确' : _ref4$message;
      return {
        type: 'email',
        message: message,
        trigger: trigger
      };
    },
    mobile: function mobile(_ref5) {
      var _ref5$trigger = _ref5.trigger,
          trigger = _ref5$trigger === void 0 ? 'onBlur' : _ref5$trigger,
          _ref5$message = _ref5.message,
          message = _ref5$message === void 0 ? '手机号码格式不正确' : _ref5$message;
      return {
        pattern: /^((13)|(14)|(15)|(16)|(17)|(18)|(19))\d{9}$/,
        message: message,
        trigger: trigger
      };
    }
  },
  Validator: {
    /**
     * 校验是否为整数
     * @param rule
     * @param value
     * @param callback
     */
    number: function number(value, rule) {
      return {
        validator: function validator(value, rule) {
          return !(Tool.isNotNull(value) && !Pattern.isInteger(value));
        },
        message: '请输入整数',
        trigger: 'onBlur'
      };
    },

    /**
     * 校验是否为正整数
     * @param rule
     * @param value
     * @param callback
     */
    positiveNumber: function positiveNumber(value, rule) {
      return {
        validator: function validator(value, rule) {
          return !(Tool.isNotNull(value) && (!Pattern.isInteger(value) || parseInt(value) <= 0));
        },
        message: '请输入正整数',
        trigger: 'onBlur'
      };
    },

    /**
     * 校验是否为小数
     * @param rule
     * @param value
     * @param callback
     */
    decimal: function decimal(value, rule) {
      return {
        validator: function validator(value, rule) {
          return !(Tool.isNotNull(value) && !Pattern.isDecimal(value));
        },
        message: '请输入小数',
        trigger: 'onBlur'
      };
    },

    /**
     * 校验是否为大于0的小数
     * @param rule
     * @param value
     * @param reject
     */
    positiveDecimal: function positiveDecimal(value, rule) {
      return {
        validator: function validator(value, rule) {
          return !(Tool.isNotNull(value) && (!Pattern.isDecimal(value) || parseInt(value) <= 0));
        },
        message: '请输入大于0的小数',
        trigger: 'onBlur'
      };
    },

    /**
     * 校验是否为合法的ip地址
     * @param rule
     * @param value
     * @param reject
     */
    ip: function ip() {
      return {
        validator: function validator(value, rule) {
          return !(Tool.isNotNull(value) && !Pattern.isIp(value));
        },
        message: '请输入正确的IP地址',
        trigger: 'onBlur'
      };
    },

    /**
     * 校验是否为手机号码或者固话
     * @param rule
     * @param value
     * @param reject
     */
    telephoneMobile: function telephoneMobile() {
      return {
        validator: function validator(value, rule) {
          return !(Tool.isNotNull(value) && !Pattern.isTelephoneOrMobile(value));
        },
        message: '请输入正确的手机号码或者固话号码',
        trigger: 'onBlur'
      };
    },

    /**
     * 校验是否固话
     * @param rule
     * @param value
     * @param reject
     */
    telephone: function telephone() {
      return {
        validator: function validator(value, rule) {
          return !(Tool.isNotNull(value) && !Pattern.isTelephone(value));
        },
        message: '请输入正确的固定电话',
        trigger: 'onBlur'
      };
    },

    /**
     * 校验是否为手机号码
     * @param rule
     * @param value
     * @param reject
     */
    mobile: function mobile() {
      return {
        validator: function validator(value, rule) {
          return !(Tool.isNotNull(value) && !Pattern.isMobile(value));
        },
        message: '请输入正确的手机号码',
        trigger: 'onBlur'
      };
    },

    /**
     * 校验是否为电子邮箱
     * @param rule
     * @param value
     * @param reject
     */
    email: function email() {
      return {
        validator: function validator(value, rule) {
          return !(Tool.isNotNull(value) && !Pattern.isEmail(value));
        },
        message: '请输入正确的电子邮箱',
        trigger: 'onBlur'
      };
    },

    /**
     * 校验是否中文
     * @param rule
     * @param value
     * @param reject
     */
    chinese: function chinese() {
      return {
        validator: function validator(value, rule) {
          return !(Tool.isNotNull(value) && !Pattern.isChinese(value));
        },
        message: '请输入中文',
        trigger: 'onBlur'
      };
    },

    /**
     * 校验是否为身份证号
     * @param rule
     * @param value
     * @param reject
     */
    idCard: function idCard() {
      return {
        validator: function validator(value, rule) {
          return !(Tool.isNotNull(value) && !Pattern.isIdCard(value));
        },
        message: '请输入正确的身份证号',
        trigger: 'onBlur'
      };
    },
    minMaxSelect: function minMaxSelect(_ref6) {
      var min = _ref6.min,
          max = _ref6.max;
      var rules = [];

      if (min) {
        rules.push({
          validator: function validator(value, rule) {
            return !value || value.length >= min;
          },
          message: "\u6700\u5C11\u9009\u62E9".concat(min, "\u9879"),
          trigger: 'onBlur'
        });
      }

      if (max) {
        rules.push({
          validator: function validator(value, rule) {
            return !value || value.length <= max;
          },
          message: "\u6700\u591A\u9009\u62E9".concat(max, "\u9879"),
          trigger: 'onBlur'
        });
      }

      return rules;
    },
    minMaxLength: function minMaxLength(_ref7) {
      var min = _ref7.min,
          max = _ref7.max,
          minMessage = _ref7.minMessage,
          maxMessage = _ref7.maxMessage;
      var rules = [];

      if (min) {
        rules.push({
          validator: function validator(value, rule) {
            return !value || value.length >= min;
          },
          message: minMessage !== null && minMessage !== void 0 ? minMessage : "\u6700\u5C11".concat(min, "\u4E2A\u5B57\u7B26"),
          trigger: 'onBlur'
        });
      }

      if (max) {
        rules.push({
          validator: function validator(value, rule) {
            return !value || value.length <= max;
          },
          message: maxMessage !== null && maxMessage !== void 0 ? maxMessage : "\u6700\u591A".concat(max, "\u4E2A\u5B57\u7B26"),
          trigger: 'onBlur'
        });
      }

      return rules;
    }
  }
};
export default Form;