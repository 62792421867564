import _typeof from "/home/jenkins/agent/workspace/psychologicalhwvvw/psychological-we-web/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.keys.js";
import Tool from './tool';
var Json = {
  isNull: function isNull(json) {
    return Tool.isNull(json) || JSON.stringify(json) == '{}';
  },
  isNotNull: function isNotNull(json) {
    return !Json.isNull(json);
  },

  /**
   * 判断是否为json对象
   * @param json
   * @returns {boolean}
   */
  isJson: function isJson(json) {
    if (json == null || json == undefined || json == '') {
      return false;
    }

    if (typeof json == 'string') {
      try {
        var obj = JSON.parse(json);

        if (_typeof(obj) == 'object' && obj) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.log('error：' + json + '!!!' + e);
        return false;
      }
    } else if (_typeof(json) == 'object') {
      return true;
    }

    return false;
  },

  /**
   * json转url参数，如{a:123,b:456} to a=123&b=456
   * @param json
   * @param callback
   */
  convertToUrlParam: function convertToUrlParam(json) {
    if (Json.isNull(json)) {
      return '';
    }

    var params = [];
    Json.forEach(json, function (key, value) {
      params.push("".concat(key, "=").concat(value));
    });
    return '?' + params.join('&');
  },

  /**
   * 合并两个json
   * @param target 目标json
   * @param source 源json
   * @param exist 是否
   * @param copyNull
   * @returns {any}
   */
  merge: function merge(target, source) {
    var exist = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var copyNull = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

    if (!Json.isJson(target) || !Json.isJson(source)) {
      return target;
    }

    var newTarget = Json.copy(target);
    exist = exist || false;
    copyNull = copyNull || false;

    for (var key in source) {
      var copy = source[key] || null;

      if (copy || copyNull) {
        if (exist && target.hasOwnProperty(key) || !exist) {
          newTarget[key] = copy;
        }
      }
    }

    return newTarget;
  },
  copy: function copy(json) {
    if (json) {
      return Tool.clone(json);
    }

    return null;
  },
  size: function size(json) {
    if (Json.isNull(json)) {
      return 0;
    }

    var size = 0;

    for (var key in json) {
      size++;
    }

    return size;
  },

  /**
   * 循环json
   * @param json
   * @param callback
   */
  forEach: function forEach(json, callback) {
    if (Json.isNull(json)) {
      return;
    }

    for (var key in json) {
      callback && callback(key, json[key]);
    }
  },

  /**
   * 深度比较
   * @param x
   * @param y
   * @returns {boolean}
   */
  deepEqual: function deepEqual(x, y) {
    // 指向同一内存时
    if (x === y) {
      return true;
    } else if (_typeof(x) == 'object' && x != null && _typeof(y) == 'object' && y != null) {
      if (Object.keys(x).length != Object.keys(y).length) {
        return false;
      }

      for (var prop in x) {
        if (typeof x[prop] != 'function') {
          if (y.hasOwnProperty(prop)) {
            if (!Json.deepEqual(x[prop], y[prop])) {
              return false;
            }
          } else {
            return false;
          }
        }
      }

      return true;
    } else if (typeof x == 'function' && typeof y == 'function') {
      return true;
    } else {
      return false;
    }
  },

  /**
   * 循环json
   * @param json
   * @param callback
   */
  buildParams: function buildParams(json) {
    if (Json.isNull(json)) {
      return;
    }

    var array = new Array();

    for (var key in json) {
      array.push("".concat(key, "=").concat(json[key]));
    }

    return array.join("&");
  }
};
export default Json;