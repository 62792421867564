import util from '@/libs/util';
export default {
  get: function get() {
    return util.cookies.get('token') || false;
  },
  set: function set() {
    var token = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return util.cookies.set('token', token);
  },
  setUUid: function setUUid() {
    var uuid = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return util.cookies.set('uuid', uuid);
  },
  remove: function remove() {
    util.cookies.set('token', '');
    util.cookies.remove('token');
  }
};