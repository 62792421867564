/* unplugin-vue-components disabled */import _objectSpread from "/home/jenkins/agent/workspace/psychologicalhwvvw/psychological-we-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/home/jenkins/agent/workspace/psychologicalhwvvw/psychological-we-web/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.function.name.js";
import { NavBar } from 'vant';
import { mapState } from 'vuex';
export default {
  name: 'InfoLayout',
  components: _defineProperty({}, NavBar.name, NavBar),
  data: function data() {
    return {
      title: '',
      isLoading: true
    };
  },
  watch: {
    $route: {
      handler: function handler(currentRoute) {
        this.title = currentRoute.meta.navTitle || currentRoute.meta.title;
      },
      deep: true,
      immediate: true
    }
  },
  computed: _objectSpread({}, mapState('admin/page', ['keepAlive'])),
  methods: {
    back: function back() {
      var back = this.$router.currentRoute.value.meta.back;

      if (back) {
        this.$router.push({
          name: back
        });
      } else {
        history.back();
      }
    },
    refresh: function refresh() {
      location.reload();
    }
  }
};