import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { cloneDeep } from 'lodash';
import util from '@/libs/util';
var Tool = {
  /**
   * 深度克隆
   * @param obj
   * @returns {null|*}
   */
  clone: function clone(obj) {
    if (!obj) {
      return null;
    }

    return cloneDeep(obj);
  },

  /**
   * 比较对象内容
   * @param original 原始对象
   * @param target 目标对象
   * @returns {boolean}
   */
  compare: function compare(original, target) {
    return JSON.stringify(original) === JSON.stringify(target);
  },

  /**
   * 判断对象是否为空
   * @param val
   * @returns {boolean}
   */
  isNull: function isNull(val) {
    if (typeof val === 'number' && !isNaN(val) && val === 0) {
      return false;
    }

    return val == null || val == undefined || val == '' || val == 'null' || val == 'undefined' || val.length == 0;
  },

  /**
   * 判断对象是否非空
   * @param val
   * @returns {boolean}
   */
  isNotNull: function isNotNull(val) {
    return !Tool.isNull(val);
  },

  /**
   * 获取上传header
   * @returns {Promise<{"user-sid"}>}
   */
  getUploadHeader: function getUploadHeader() {
    var token = util.cookies.get('token');
    return {
      'user-sid': token
    };
  },

  /**
   * 转换为文件大小
   * @param size
   * @param pointLength
   * @param units
   * @returns {string}
   */
  formatSize: function formatSize(size, pointLength, units) {
    var unit;
    units = units || ['B', 'K', 'M', 'G'];

    while ((unit = units.shift()) && size > 1024) {
      size = size / 1024;
    }

    return (unit === 'B' ? size : size.toFixed(pointLength === undefined ? 2 : pointLength)) + unit;
  },

  /**
   * 去掉html元素
   * @param val
   * @returns {*}
   */
  getText: function getText(val) {
    if (Tool.isNull(val)) {
      return val;
    }

    return val.replace(/<\/?.+?>/g, '').replace(/ /g, '').replace(/&\/?.+?;/g, '');
  },
  getHtmlFamily: function getHtmlFamily(html) {
    var div = document.createElement('div');
    div.innerHTML = html;
    var children = div.children;

    if (Tool.isNull(children)) {
      var p = document.createElement('div');
      p.innerText = html;
      div.innerHTML = p.outerHTML;
      children = div.children;
    }

    var family = [];

    for (var j = 0; j < children.length; j++) {
      var node = children[j];
      var styles = {};

      for (var i = 0; i < node.style.length; i++) {
        var styleName = node.style.item(i);
        styles[styleName] = node.style.getPropertyValue(styleName);
      }

      family.push({
        nodeName: node.nodeName,
        text: node.innerText,
        style: styles
      });
    }

    return family;
  },
  getPercent: function getPercent(num, total) {
    num = parseFloat(num);
    total = parseFloat(total);

    if (isNaN(num) || isNaN(total)) {
      return '-';
    }

    return total <= 0 ? 0 : Math.round(num / total * 10000) / 100;
  }
};
export default Tool;