import request from '@/plugins/request';
var UserAPi = {};
/**
 * 保存用户信息
 * @param data
 * @returns {*}
 */

UserAPi.saveUserInfo = function () {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    phone: null,
    nickName: null,
    avatar: null,
    sex: null
  };
  return request({
    url: "/user/saveUserInfo",
    method: 'post',
    data: data
  });
};
/**
 * 获取登录用户信息
 * @returns {*}
 */


UserAPi.getUserInfo = function () {
  return request({
    url: "/user/getUserInfo",
    method: 'get'
  });
};
/**
 * 绑定学籍信息
 * @returns {*}
 */


UserAPi.bindStudentInfo = function (data) {
  return request({
    url: "/user/bindStudentInfo",
    method: 'post',
    data: data
  });
};
/**
 * 保存学籍信息
 * @param data
 * @returns {*}
 */


UserAPi.saveStudentInfo = function () {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    studentNumber: null,
    studentName: null,
    collegeId: null,
    collegeCode: null,
    collegeName: null,
    clzssName: null,
    phone: null,
    validCode: null
  };
  return request({
    url: "/user/saveStudentInfo",
    method: 'post',
    data: data
  });
};

UserAPi.getOrgnizationTree = function () {
  return request({
    url: "/user/getOrgnizationTree",
    method: 'get'
  });
};

export default UserAPi;