import _objectSpread from "/home/jenkins/agent/workspace/psychologicalhwvvw/psychological-we-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "/home/jenkins/agent/workspace/psychologicalhwvvw/psychological-we-web/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import { createRouter, createWebHistory, createWebHashHistory, createMemoryHistory } from 'vue-router';
import util from '@/libs/util';
import Setting from '@/setting'; // 路由数据

import routes from './routes';
import Token from '@/plugins/auth/token';
import WxApi from '@/api/wx';
import Utils from '@/utils';
import store from '@/store/index';
import { config, judgePhone } from '@/utils/wxConfig';
import { Dialog } from "vant"; // 导出路由 在 main.js 里使用

var router = createRouter({
  history: Setting.routerMode === 'history' ? createWebHistory(Setting.routerBase) : Setting.routerMode === 'hash' ? createWebHashHistory(Setting.routerBase) : createMemoryHistory(Setting.routerBase),
  routes: routes
});
/**
 * 路由拦截
 * 权限验证
 */

router.beforeEach(function (to, from, next) {
  //未登录
  var code = to.query.code;

  if (judgePhone() === 'ios') {
    if (from.path === '/') {
      var url = location.href.split("#")[0];
      var jsApiList = [];
      Utils.Json.forEach(Utils.Constant.JsApi, function (key, value) {
        jsApiList.push.apply(jsApiList, _toConsumableArray(value));
      });
      config(url, jsApiList);
    }
  }

  if (code && 'state' in to.query) {
    store.dispatch('admin/account/login', {
      code: code
    }).then(function () {
      delete to.query.code;
      delete to.query.state;
      next(_objectSpread({}, to));
    }).catch(function () {
      next({
        name: 'index'
      });
    });
  } else if (to.meta.needLogin && !Token.get()) {
    var redirectUrl = process.env.VUE_APP_URL + to.fullPath;

    if (Utils.Json.isNotNull(to.query)) {
      redirectUrl += "?" + Utils.Json.buildParams(to.query);
    }

    next({
      name: 'login',
      path: '/login',
      query: {
        redirectUrl: redirectUrl
      },
      replace: true
    });
  } else if (to.name === 'login' && Token.get()) {
    next(_objectSpread({}, from));
  } else {
    next();
  }
});
router.afterEach(function (to) {
  // 更改标题
  util.title({
    title: to.meta.title
  }); // 返回页面顶端

  window.scrollTo(0, 0);
});
export default router;