/* unplugin-vue-components disabled */import { Tabbar as __unplugin_components_1 } from 'vant/es';import 'vant/es/tabbar/style/index';
import { TabbarItem as __unplugin_components_0 } from 'vant/es';import 'vant/es/tabbar-item/style/index';
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  style: {
    "padding-bottom": "100px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_view = _resolveComponent("router-view");

  var _component_van_tabbar_item = __unplugin_components_0;

  var _component_van_tabbar = __unplugin_components_1;

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_router_view, null, {
    default: _withCtx(function (_ref) {
      var Component = _ref.Component;
      return [(_openBlock(), _createBlock(_KeepAlive, {
        include: _ctx.keepAlive
      }, [_createElementVNode("div", _hoisted_1, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))])], 1032
      /* PROPS, DYNAMIC_SLOTS */
      , ["include"]))];
    }),
    _: 1
    /* STABLE */

  }), _createVNode(_component_van_tabbar, {
    class: "van-safe-area-bottom",
    modelValue: $data.active,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.active = $event;
    }),
    "safe-area-inset-bottom": "",
    onChange: $options.handleChangeTabbar
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_van_tabbar_item, {
        icon: "home-o",
        name: "index"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode("首页")];
        }),
        _: 1
        /* STABLE */

      }), _ctx.hasUnion ? (_openBlock(), _createBlock(_component_van_tabbar_item, {
        key: 0,
        icon: "underway-o",
        name: "appointment"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode("预约")];
        }),
        _: 1
        /* STABLE */

      })) : _createCommentVNode("v-if", true), _createVNode(_component_van_tabbar_item, {
        icon: "manager-o",
        name: "home"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode("个人中心")];
        }),
        _: 1
        /* STABLE */

      })];
    }),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["modelValue", "onChange"])], 64
  /* STABLE_FRAGMENT */
  );
}