import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.number.constructor.js";
import Tool from './tool';
var Pattern = {
  /**
   * 是否为整数
   * @param value
   * @returns {boolean}
   */
  isInteger: function isInteger(value) {
    return /^-?\d+$/.test(value);
  },

  /**
   * 校验是否位数字
   * @param value
   * @returns {boolean}
   */
  isNumber: function isNumber(value) {
    return /^-?[1-9][0-9]*([.][0-9]+)?$/.test(value);
  },

  /**
   * 是否为小数
   * @param value
   * @returns {boolean}
   */
  isDecimal: function isDecimal(value) {
    return Number(value) && /^-?\d+\.\d+$/.test(value);
  },

  /**
   * 是否为合法的url
   * @param value
   * @returns {boolean}
   */
  isUrl: function isUrl(value) {
    return Tool.isNotNull(value) && /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/.test(value);
  },

  /**
   * 是否为ip地址
   * @param value
   * @returns {boolean}
   */
  isIp: function isIp(value) {
    var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
    return Tool.isNotNull(value) && reg.test(value);
  },

  /**
   * 是否为手机号码或者固定电话
   * @param value
   * @returns {boolean}
   */
  isTelephoneOrMobile: function isTelephoneOrMobile(value) {
    return Tool.isNotNull(value) && (Pattern.isTelephone(value) || Pattern.isMobile(value));
  },

  /**
   * 是否为固定电话
   * @param value
   * @returns {boolean}
   */
  isTelephone: function isTelephone(value) {
    return Tool.isNotNull(value) && /0\d{2,3}-\d{7,8}/.test(value);
  },

  /**
   * 是否为固定电话
   * @param value
   * @returns {boolean}
   */
  isMobile: function isMobile(value) {
    return Tool.isNotNull(value) && /^[1][3-9][0-9]{9}$/.test(value);
  },

  /**
   * 是否邮箱
   * @param value
   */
  isEmail: function isEmail(value) {
    return Tool.isNotNull(value) && /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/.test(value);
  },

  /**
   * 是否中文
   * @param value
   */
  isChinese: function isChinese(value) {
    return Tool.isNotNull(value) && /^[\u0391-\uFFE5]+$/.test(value);
  },

  /**
   * 是否为身份证号
   * @param value
   * @returns {boolean}
   */
  isIdCard: function isIdCard(idCard) {
    if (Tool.isNull(idCard)) {
      return false;
    } //15位和18位身份证号码的正则表达式


    var regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/; //如果通过该验证，说明身份证格式正确，但准确性还需计算

    if (regIdCard.test(idCard)) {
      if (idCard.length == 18) {
        var idCardWi = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2); //将前17位加权因子保存在数组里

        var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2); //这是除以11后，可能产生的11位余数、验证码，也保存成数组

        var idCardWiSum = 0; //用来保存前17位各自乖以加权因子后的总和

        for (var i = 0; i < 17; i++) {
          idCardWiSum += idCard.substring(i, i + 1) * idCardWi[i];
        }

        var idCardMod = idCardWiSum % 11; //计算出校验码所在数组的位置

        var idCardLast = idCard.substring(17); //得到最后一位身份证号码
        //如果等于2，则说明校验码是10，身份证号码最后一位应该是X

        if (idCardMod == 2) {
          if (idCardLast == 'X' || idCardLast == 'x') {
            return true;
          } else {
            return false;
          }
        } else {
          //用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
          if (idCardLast == idCardY[idCardMod]) {
            return true;
          } else {
            return false;
          }
        }
      }
    } else {
      return false;
    }
  }
};
export default Pattern;