import Tool from './tool';
import Toast from './toast';
import Constant from './constant';
import Array from './array';
import Json from './json';
import Form from './form';
import Pattern from './pattern';
var Utils = {
  Tool: Tool,
  Toast: Toast,
  Constant: Constant,
  Array: Array,
  Json: Json,
  Form: Form,
  Pattern: Pattern
};
export default Utils;