import "/home/jenkins/agent/workspace/psychologicalhwvvw/psychological-we-web/node_modules/core-js/modules/es.array.iterator.js";
import "/home/jenkins/agent/workspace/psychologicalhwvvw/psychological-we-web/node_modules/core-js/modules/es.promise.js";
import "/home/jenkins/agent/workspace/psychologicalhwvvw/psychological-we-web/node_modules/core-js/modules/es.object.assign.js";
import "/home/jenkins/agent/workspace/psychologicalhwvvw/psychological-we-web/node_modules/core-js/modules/es.promise.finally.js";
// Vue
import { createApp, h, getCurrentInstance } from 'vue';
import App from '@/App'; // 混合

import mixinApp from '@/mixins/app'; // 插件

import plugins from '@/plugins'; // store

import store from '@/store/index'; // ViewUIPlus

import ViewUIPlus from 'view-ui-plus'; // 菜单和路由

import router from '@/router';
/* import the fontawesome core */

import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
/* import specific icons */

import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
/* add icons to the library */

library.add(faUserSecret); // 使用样式，修改主题可以在 styles 目录下创建新的主题包并修改 View UI Plus 默认的 less 变量
// 参考 https://www.iviewui.com/docs/guide/theme

import 'vant/lib/index.css'; // vant不分无法解析的样式需要手动引入

import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import 'vant/es/notify/style';
import 'vant/es/image-preview/style';
import './styles/index.less';
import { ConfigProvider } from 'vant'; // 第一次进入时，取菜单数据用于更新

var isFirstRouteChange = true;
var app = createApp({
  mixins: [mixinApp],
  render: function render() {
    return h(App);
  },
  created: function created() {
    this.$store.commit('admin/page/keepAliveRefresh'); // 加载用户登录的数据

    this.$store.dispatch('admin/user/reload'); // 将根实例存全局，可在特殊场景下调用

    if (window) {
      window.$app = getCurrentInstance();
    }
  },
  watch: {
    // 监听路由 控制侧边栏显示 标记当前顶栏菜单（如需要）
    '$route': function $route(to, from) {
      this.appRouteChange(to, from);
    }
  }
});
app.use(router);
app.use(store);
app.use(ViewUIPlus, {
  transfer: true
}); // app.use(Vant);

app.use(plugins);
app.use(ConfigProvider);
app.component('font-awesome-icon', FontAwesomeIcon);
import Utils from '@/utils';
app.config.globalProperties.$Utils = Utils;
app.mount('#app');