import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import WxApi from "@/api/wx";
import wx from "weixin-js-sdk";
import { Dialog } from "vant"; //判断当前系统是ios还是Android

export function judgePhone() {
  var u = navigator.userAgent;
  var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

  if (isIOS) {
    return 'ios';
  } else {
    return 'android';
  }
} //config验证

export function config(url) {
  var jsApiList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  WxApi.getJsapiTicket(url).then(function (result) {
    wx.config({
      debug: false,
      // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
      appId: result.appId,
      // 必填，公众号的唯一标识
      timestamp: result.timestamp,
      // 必填，生成签名的时间戳
      nonceStr: result.nonceStr,
      // 必填，生成签名的随机串
      signature: result.signature,
      // 必填，签名
      jsApiList: jsApiList // 必填，需要使用的JS接口列表 这里填写需要用到的微信api openlocation为使用微信内置地图查看位置接口

    });
  });
}