import _typeof from "/home/jenkins/agent/workspace/psychologicalhwvvw/psychological-we-web/node_modules/@babel/runtime/helpers/esm/typeof.js";
import _objectSpread from "/home/jenkins/agent/workspace/psychologicalhwvvw/psychological-we-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import store from '@/store';
import util from '@/libs/util';
export default {
  install: function install(app, options) {
    // 快速打印 log
    app.config.globalProperties.$log = _objectSpread(_objectSpread({}, util.log), {}, {
      push: function push(data) {
        if (typeof data === 'string') {
          // 如果传递来的数据是字符串
          // 赋值给 message 字段
          // 为了方便使用
          // eg: this.$log.push('foo text')
          store.dispatch('admin/log/push', {
            message: data
          });
        } else if (_typeof(data) === 'object') {
          // 如果传递来的数据是对象
          store.dispatch('admin/log/push', data);
        }
      }
    });
  }
};